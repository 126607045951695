import React from 'react';
import Layout from '../components/layout';
import Section from '../components/Section/Section';
import SectionContent from '../components/SectionContent/SectionContent';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <SectionContent centered>
        <h2>404 NOT FOUND</h2>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </SectionContent>
    </Section>
  </Layout>
);

export default NotFoundPage;
